<template>
  <b-modal
    id="modal-invoice-payment"
    ref="refModal"
    title="Xác nhận thanh toán"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="openModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="item"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <invoice-table :data="[item]" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span
              v-if="item.totalRemain > 0"
              class="text-danger"
            >Bạn cần thu của cư dân số tiền là: <strong>{{ Number(item.totalRemain).toLocaleString() }}</strong></span>
            <span
              v-if="item.totalRemain < 0"
              class="text-danger"
            >Bạn cần thanh toán lại cho cư dân số tiền là: <strong>{{ Number(Math.abs(item.totalRemain)).toLocaleString() }}</strong></span>
          </b-col>
        </b-row>
        <hr>

        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Số tiền"
              rules="required"
            >
              <number-input
                v-model="itemLocal.amount"
                required
                :default-value="itemLocal.amount"
                label="Số tiền thu"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Ngày thực thu"
              rules="required"
            >
              <select-date
                v-model="itemLocal.issueDate"
                required
                :default-value="itemLocal.issueDate"
                label="Ngày thực thu"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Tên người nộp"
              rules="required"
            >
              <b-form-group
                label="Tên người nộp"
              >
                <template
                  v-slot:label
                >
                  Tên người nộp
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="payer"
                  v-model="itemLocal.payer"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Ghi chú"
            >
              <b-form-textarea
                v-model="itemLocal.note"
                rows="3"
              />
            </b-form-group>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import InvoiceTable from './InvoiceTable.vue';
import useInvoicePaymentModal from './useInvoicePaymentModel';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    InvoiceTable,

    SelectDate,
    NumberInput,

  },
  props: {
    item: {
      type: [Object, null],
      required: true,
    },
  },
  data() {
    return {
      required,

    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      openModal,
      onSubmit,
    } = useInvoicePaymentModal(props, emit, refFormObserver);

    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    return {
      isTingTong,
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      openModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
