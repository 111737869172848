<template>
  <b-form-group
    v-if="isEnableBed"
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required || beds.length > 0"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="bed"
      v-model="bed"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="beds"
      :disabled="room == null || !enable"
      placeholder="Giường"
      @input="bedChanged"
      @open="onOpen"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { isEnableBed } from '@/auth/utils';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bed: this.defaultValue,
      beds: [],
    };
  },

  watch: {
    room(newVal) {
      this.bed = null;
      this.bedChanged();
      if (newVal) {
        this.fetchBeds();
      } else {
        this.beds = [];
      }
    },
  },
  methods: {
    bedChanged() {
      this.$emit('input', this.bed);
    },
    onOpen() {
      this.fetchBeds();
    },
    fetchBeds() {
      const filter = { roomId: this.room.id };
      if (this.filterActive) {
        filter.active = this.filterActive;
      }
      useJwt.getBeds({ filter, sort: { field: 'name', type: 'asc' } }).then(res => {
        this.beds = res.data.items;
        this.$emit('listBeds', res.data.items);
      });
    },
  },
  setup() {
    return { isEnableBed };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
