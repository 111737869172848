<template>
  <b-modal
    id="modal-create-multiple-invoice"
    ref="refModalCreateMultiple"
    title="Hóa đơn"
    size="xl"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    scrollable
    no-close-on-backdrop
    @show="openModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner

        class="text-center"
        variant="secondary"
      />
    </div>
    <!-- Body -->
    <validation-observer
      v-else
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-primary text-uppercase">
              1. Thông tin chung
            </div>
          </b-col>
        </b-row>

        <b-row>

          <b-col md="3">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="apartment"
                :filter-active="true"
                required
                :default-value="apartment"
                :enable-default-select="true"
                label="Tòa nhà"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="3">
            <select-month
              v-model="month"
              required
              :default-value="month"
              label="Kỳ thanh toán"
            />
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              name="Ngày lập"
              rules="required"
            >
              <select-date
                v-model="issueDate"
                required
                :default-value="issueDate"
                label="Ngày lập"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              name="Hạn thanh toán"
              rules="required"
            >
              <select-date
                v-model="dueDate"
                required
                :default-value="dueDate"
                label="Hạn thanh toán"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <hr class="mt-1">
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Danh sách hợp đồng
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <create-multiple-invoice-table-vue
              :data="itemLocal"
              :columns="columns"
            />
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  VBTooltip,
  VBModal,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
import useCreateMultipleInvoiceModal from './useCreateMultipleInvoiceModal';
import CreateMultipleInvoiceTableVue from './CreateMultipleInvoiceTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    SelectApartment,
    SelectDate,
    SelectMonth,
    CreateMultipleInvoiceTableVue,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {

  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalCreateMultiple,
      refForm,
      itemLocal,
      isSubmitting,
      apartment,
      month,
      contracts,
      dueDate,
      issueDate,
      isLoading,
      columns,
      resetModal,
      onSubmit,
      openModal,
    } = useCreateMultipleInvoiceModal(props, emit, refFormObserver);

    return {
      refModalCreateMultiple,
      refForm,
      itemLocal,
      isSubmitting,
      apartment,
      month,
      dueDate,
      issueDate,
      contracts,
      isLoading,
      columns,
      resetModal,
      onSubmit,
      openModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

    };
  },
};
</script>
