<!-- eslint-disable no-restricted-syntax -->
<template>
  <div class="invoice-items-table-container">
    <vue-good-table
      :columns="columns"
      :rows="data"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span v-if=" props.column.field === 'name' ">
          {{ props.row.contract.name }} ({{ props.row.room ? props.row.room.name : '' }})

        </span>

        <!-- Column: Chọn ngày -->
        <span
          v-else-if="props.column.field.includes('.startDate')"
        >
          <select-date
            :id="`${props.column.field}-${props.row.originalIndex}`"
            v-model="props.row.fees[props.row.originalIndex].startDate"
            :default-value="props.row.fees[props.row.originalIndex].startDate"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Chọn ngày -->
        <span
          v-else-if="props.column.field.includes('.endDate')"
        >
          <select-date
            :id="props.column.field"
            v-model="props.row.fees[props.row.originalIndex].endDate"
            :default-value="props.row.fees[props.row.originalIndex].endDate"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Số lượng -->
        <span
          v-else-if="props.column.field.includes('.quantity')"
        >
          <spin-button
            v-model="props.row['quantity']"
            :min="0"
            :default-value="props.row['quantity']"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Chọn chỉ số -->
        <span
          v-else-if="props.column.field.includes('.meter')"
        >
          <select-meter-log
            :id="props.row.originalIndex"
            v-model="props.row.meterLogItems"
            :append-to-body="true"
            :apartment="apartment"
            :room="room"
            :fee="props.row.feeItems"
            :meter="props.row.meterItems"
            :enable-add="true"
            :default-value="props.row.meterLogItems"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Tiền cọc -->
        <span
          v-else-if="props.column.field === 'deposit'"
        >
          <number-input
            v-model="props.row.discount"
            :default-value="props.row.discount"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Giảm giá -->
        <span
          v-else-if="props.column.field === 'discount'"
        >
          <number-input
            v-model="props.row.discount"
            :default-value="props.row.discount"
            @input="changeCell(props.row.originalIndex, props.column.field, $event)"
          />
        </span>
        <!-- Column: Thuế -->
        <span
          v-else-if="props.column.field === 'tax'"
        >
          <number-input
            v-model="props.row.tax"
            :default-value="props.row.tax"
            @input="changeCell(props.row.originalIndex, $event)"
          />
        </span>
        <!-- Column: Tạm tính -->
        <span
          v-else-if="props.column.field === 'subTotal'"
        >
          {{ props.row.total ? Number(props.row.total).toLocaleString() : 0 }}
        </span>
        <!-- Column: Tổng tiền tung dich vu -->
        <span
          v-else-if="props.column.field.includes('.total')"
        >
          {{ props.row.total ? Number(props.row.total).toLocaleString() : 100000000 }}
        </span>
        <!-- Column: Tổng tiền -->
        <span
          v-else-if="props.column.field === 'total'"
        >
          {{ props.row.total ? Number(props.row.total).toLocaleString() : 100000000 }}
        </span>

        <!-- Column: Ghi chú -->
        <span v-else-if="props.column.field === 'note'">
          <b-form-input
            id="note"
            v-model="props.row.note"
            placeholder=""
            trim
          />
        </span>

        <!-- Column: Common -->
        <span
          v-else
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>
    <small class="text-danger pt-2">{{ error }}</small>
  </div>

</template>

<script>
import {
  BSpinner,
  BFormInput,
} from 'bootstrap-vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SpinButton from '@/views/components/SpinButton.vue';
import { VueGoodTable } from 'vue-good-table';
import { required } from "@validations";
import SelectMeterLog from '@/views/components/SelectMeterLog.vue';
import useJwt from '@/auth/jwt/useJwt';
import { parseDateToString } from '@/auth/utils';

export default {
  components: {
    BSpinner,
    BFormInput,
    VueGoodTable,
    NumberInput,
    SelectDate,
    SelectMeterLog,
    SpinButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    calculateTotalForInvoiceItem: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {

    changeCell(row, field, value) {
      // eslint-disable-next-line no-unused-vars
      const [_, feeId, feeField] = field.split('.');

      let currentFee = null;

      this.data[row].fees = this.data[row].fees.map(fee => {
        if (fee.id === parseInt(feeId, 10)) {
          currentFee = { ...fee, [feeField]: value };
          return currentFee;
        }
        return fee;
      });

      if ((feeField === 'startDate' || feeField === 'endDate') && parseDateToString(currentFee.startDate) && parseDateToString(currentFee.endDate)) {

        // useJwt.calculateQuantity({ startDate: parseDateToString(currentFee.startDate), endDate: parseDateToString(currentFee.endDate) }).then(response => {

        // });
      }
    },

    onChange() {
      this.$emit('input', this.fees);
    },

  },

};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>
