<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="meter_log"
        v-model="meterLog"
        :style="{minWidth: '60px'}"
        class="style-chooser"
        :append-to-body="appendToBody"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="items"
        placeholder="Chọn chỉ số"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true"
          #list-header
        >
          <li
            class="list-header d-flex align-items-center my-50"
            @click="$bvModal.show(`modal-meter-log-${id}`)"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">Chốt công tơ</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <meter-log-modal
      v-if="enableAdd == true"
      :id="id"
      :approve="true"
      :item="defaultMeterLogItem().item"
      @refetch-meters="fetchData"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import moment from 'moment';
import MeterLogModal from "../services/meter-log/modal/MeterLogModal.vue";

export default {
  components: {
    BFormGroup,
    vSelect,
    MeterLogModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    fee: {
      type: Object,
      default: null,
    },
    meter: {
      type: Object,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      meterLog: this.defaultValue,
      items: [],
    };
  },
  watch: {

    room(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.meterLog = null;
        this.valueChanged();
        if (newVal) {
          this.fetchData();
        } else {
          this.items = [];
        }
      }
    },
  },
  created() {
    this.fetchData();
  },
  setup(props) {
    const defaultMeterLogItem = () => {
      let meterType = null;
      if (props.fee) {
        meterType = { id: props.fee.feeCategoryId, name: props.fee.feeCategoryId };
      }

      return ({
        item: {
          disableFilter: true,
          apartment: props.apartment,
          room: props.room,
          month: moment(new Date()).format("MM-YYYY"),
          issueDate: moment(new Date()).toDate(),
          meter: props.meter,
          meterType,
          meters: props.meter ? [{
            meter: props.meter, firstIndex: props.meter.lastIndex, lastIndex: props.meter.smartDevice ? parseInt(props.meter.smartDevice.currentValue, 10) : props.meter.lastIndex, issueDate: moment(new Date()).toDate(),
          }] : [],
        },
      });
    };

    return {
      defaultMeterLogItem,
    };
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.meterLog);
    },
    fetchData() {
      const filter = { onlyUnUsedLog: false };
      if (this.apartment) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room) {
        filter.roomId = this.room.id;
      }
      if (this.fee) {
        filter.type = this.fee.feeCategoryId;
      }
      if (this.meter) {
        filter.meterId = this.meter.id;
      }
      // if (this.invoiceId) {
      //   filter.invoiceId = this.invoiceId;
      // }

      useJwt.getMeterLogs({ filter }).then(res => {
        this.items = res.data.items;
      });
    },
    onOpen() {
      if (this.items.length === 0) {
        this.fetchData();
      }
    },
    onItemCreated(newItem) {
      if (newItem && newItem.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.meterLog = newItem[0];
        this.valueChanged();
      }
      this.fetchData();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
  padding: $options-padding-y $options-padding-x;
  color: $secondary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
