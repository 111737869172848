<template>
  <div>
    <span>Danh sách hóa đơn</span>
    <vue-good-table
      class="mb-1 mt-1"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Code -->
        <span v-if="props.column.field === 'remain' || props.column.field === 'total' || props.column.field === 'paid'">
          {{ Number(props.row[props.column.field]).toLocaleString() }}
        </span>

        <!-- Column: Code -->
        <span v-else-if="props.column.field === 'customer'">
          <div>
            <span>{{ props.row.customerName }}</span>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    BSpinner,

    VueGoodTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: this.data,
    };
  },
  computed: {
    getRemain() {
      let remain = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of this.rows) {
        remain += row.remain;
      }
      return remain;
    },
  },
  watch: {
    data(val) {
      this.rows = val;
    },
  },
  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Mã hóa đơn',
        field: 'code',
        sortable: false,
      },
      {
        label: 'Khách hàng',
        field: 'customer',
        sortable: false,
      },
      {
        label: 'Số tiền',
        field: 'total',
        sortable: false,
      },
      {
        label: 'Đã thanh toán',
        field: 'paid',
        sortable: false,
      },
      {
        label: 'Còn nợ',
        field: 'remain',
        sortable: false,
      },
    ];

    return {
      columns,
    };
  },
};
</script>
