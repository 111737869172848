<template>
  <div class="invoice-items-table-container">
    <vue-good-table
      :columns="columns"
      :rows="fees"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span v-if=" props.column.field === 'name' ">
          <div v-if="props.row.feeItems">{{ props.row.feeItems.name }}</div>
          <div
            v-if="props.row.feeItems && props.row.price > 0"
            class="text-secondary font-italics font-small-3"
          >
            ({{ Number(props.row.price).toLocaleString() }} đ/{{ props.row.feeItems.unit }})
          </div>
        </span>

        <!-- Column: Meter Log -->
        <span v-else-if="props.column.field === 'meterLogItems'">
          <div v-if="props.row.feeItems && props.row.feeItems.type && (props.row.feeItems.type.id === 1 || props.row.feeItems.type.id === 2)">
            <validation-provider
              #default="validationContext"
              :name="`Công tơ ${props.row.originalIndex}`"
              rules="required"
            >
              <select-meter-log
                :id="props.row.originalIndex"
                v-model="props.row.meterLogItems"
                :append-to-body="true"
                :apartment="apartment"
                :room="room"
                :fee="props.row.feeItems"
                :meter="props.row.meterItems"
                :enable-add="true"
                :invoice-id="invoiceId"
                :default-value="props.row.meterLogItems"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
              />

            </validation-provider>

          </div>

        </span>

        <!-- Column: Price -->
        <span v-else-if="props.column.field === 'price'">

          <div v-if="props.row.feeItems && ((props.row.feeItems.type && props.row.feeItems.type.id === 4) || props.row.feeItems.feeCategoryId === 'deposit')">
            <validation-provider
              #default="validationContext"
              :name="`Đơn giá ${props.row.originalIndex}`"
              rules="required"
            >
              <number-input
                v-model="props.row.price"
                :default-value="props.row.price"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['price'], 'price', props.row.originalIndex)"
              />

            </validation-provider>
          </div>
          <div v-else>
            <div
              v-if="props.row.feeItems && props.row.price > 0"
              class="text-secondary font-italics font-small-3"
            >
              {{ Number(props.row.price).toLocaleString() }} đ / {{ props.row.feeItems.unit }}
            </div>
            <div
              v-else-if="props.row.feeItems.feeCategoryId === 'lease' && contract"
              class="text-secondary font-italics font-small-3"
            >
              {{ Number(contract.price).toLocaleString() }} đ
            </div>
          </div>

        </span>

        <!-- Column: Quantity -->
        <span v-else-if="props.column.field === 'quantity'">

          <div v-if="props.row.feeItems && props.row.feeItems.type && props.row.feeItems.type.id !== 1 && props.row.feeItems.type.id !== 2 && props.row.feeItems.feeCategoryId !== 'lease' && props.row.feeItems.feeCategoryId !== 'deposit'">
            <validation-provider
              #default="validationContext"
              :name="`Số lượng ${props.row.originalIndex}`"
              rules="required"
            >
              <spin-button
                v-model="props.row['quantity']"
                :min="1"
                :default-value="props.row['quantity']"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['quantity'], 'quantity', props.row.originalIndex)"
              />

            </validation-provider>
          </div>

          <div v-else-if="props.row.feeItems && ['deposit', 'lease'].includes(props.row.feeItems.feeCategoryId )">
            {{ props.row.feeItems.feeCategoryId === 'lease' ? Number(props.row.quantity).toFixed(2) + ` (${dateDiff(props.row)})`.toLocaleString() : Number(props.row.quantity).toLocaleString() }}
          </div>
          <div v-else>
            <div
              v-if="props.row.meterLogItems"
              class="text-secondary font-italics font-small-3"
            >
              {{ Number(props.row.meterLogItems.lastIndex - props.row.meterLogItems.firstIndex).toLocaleString() }}
            </div>
          </div>

        </span>

        <!-- Column: Coefficient -->
        <span v-else-if=" props.column.field === 'coefficient' ">
          <validation-provider
            v-if="props.row.feeItems.feeCategoryId !== 'deposit' && props.row.feeItems.feeCategoryId !== 'lease' && !isAutoCalculateCoefficientByDate"
            #default="validationContext"
            :name="`${props.column.field} ${props.row.originalIndex}`"
            rules="required"
          >
            <number-input
              :id="`coefficient-${props.row.originalIndex}}`"
              v-model="props.row[props.column.field]"
              :default-value="props.row[props.column.field]"
              :error="validationContext.errors[0]"
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            />
            <!-- <b-form-group :state="getValidationState(validationContext)">
              <b-form-input
                v-model="props.row[props.column.field]"
                :default-value="props.row[props.column.field]"
                @change="changeCell(props.row['coeficient'], 'coeficient', props.row.originalIndex)"
              />
            </b-form-group> -->

          </validation-provider>
          <span v-else>{{ Number(props.row.coefficient).toFixed(2) }}</span>
        </span>

        <!-- Column: Billing Date -->
        <span v-else-if="props.column.field === 'startDate' || props.column.field === 'endDate'">

          <div v-if="props.column.field === 'startDate'">
            <span v-if="type && parseInt(type.id, 10) === 7">{{ props.row.startDate }}</span>
            <div v-else>
              <validation-provider
                v-if="props.column.field === 'startDate' && props.row.feeItems.feeCategoryId !== 'deposit'"
                #default="validationContext"
                :name="`${props.column.label} ${props.row.originalIndex}`"
                rules="required"
              >

                <select-date
                  :id="`start-date-${props.row.originalIndex}}`"
                  v-model="props.row.startDate"
                  :default-value="props.row.startDate"
                  :error="validationContext.errors[0]"
                  @input="changeCell(props.row['startDate'], 'startDate', props.row.originalIndex)"
                />
              </validation-provider>
            </div>

          </div>
          <div v-if="props.column.field === 'endDate'">
            <span v-if="type && parseInt(type.id, 10) === 7">{{ props.row.endDate }}</span>
            <div v-else>
              <validation-provider
                v-if="props.column.field === 'endDate' && props.row.feeItems.feeCategoryId !== 'deposit'"
                #default="validationContext"
                :name="`Đến ngày ${props.row.originalIndex}`"
                rules="required"
              >
                <select-date
                  :id="`end-date-${props.row.originalIndex}}`"
                  v-model="props.row.endDate"
                  :default-value="props.row.endDate"
                  :error="validationContext.errors[0]"
                  @input="changeCell(props.row['endDate'], 'endDate', props.row.originalIndex)"
                />

              </validation-provider>
            </div>

          </div>

        </span>

        <!-- Column: Billing Date -->
        <span
          v-else-if="props.column.field === 'total'"
        >
          <number-input
            v-if="userConfiguration && userConfiguration.allowEditTotalInvoice"
            v-model="props.row.total"
            :default-value="props.row.total"
            @input="changeCell(props.row['total'], 'total', props.row.originalIndex)"
          />
          <span v-else>{{ props.row.total ? Number(props.row.total).toLocaleString() : 0 }}</span>

        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions
            name="Dịch vụ"
            :hide-edit="true"
            @on-delete="onDeleteItem(props.row)"
          />
        </span>

        <!-- Column: Common -->
        <span
          v-else
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>
    <small class="text-danger pt-2">{{ error }}</small>
  </div>

</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SpinButton from '@/views/components/SpinButton.vue';
import { VueGoodTable } from 'vue-good-table';
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider } from "vee-validate";
import { required, double } from "@validations";
import SelectMeterLog from '@/views/components/SelectMeterLog.vue';
import useJwt from '@/auth/jwt/useJwt';
import moment from 'moment';
import { getServiceFeeCalculationType, getUserConfiguration, parseDateToString } from '@/auth/utils';

export default {
  components: {
    BSpinner,
    VueGoodTable,
    TableActions,
    NumberInput,
    SelectDate,
    SelectMeterLog,
    SpinButton,
    ValidationProvider,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    invoiceId: {
      type: Number,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
    type: {
      type: Object,
      default: null,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    calculateTotalForInvoiceItem: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      fees: this.rows,
      required,
      double,
      userConfiguration: getUserConfiguration(),
    };
  },

  watch: {
    rows(val) {
      this.fees = val;
    },
  },
  methods: {
    dateDiff(item) {
      const dStartDate = moment(item.startDate, 'DD-MM-YYYY', true).isValid() ? moment(item.startDate, 'DD-MM-YYYY', true) : moment(item.startDate);
      const dEndDate = moment(item.endDate, 'DD-MM-YYYY', true).isValid() ? moment(item.endDate, 'DD-MM-YYYY', true) : moment(item.endDate);
      return Math.abs(moment(dStartDate).diff(moment(dEndDate), 'days')) + 1;
    },
    changeCell(changedData, column, row) {
      if (this.fees[row][column] === changedData) { return; }

      this.fees[row][column] = changedData;

      // const serviceFeeCalculationType = getServiceFeeCalculationType();
      const userConfiguration = getUserConfiguration();

      // Trong TH la tien nha thi phai tinh toan lai so luong va tong tien

      let calculateQuantityByDay = false;

      if (column !== 'total') {
        if (this.fees[row].feeItems.feeCategoryId === 'lease') {
          calculateQuantityByDay = true;
        } else if (!['deposit', 'move', 'transfer'].includes(this.fees[row].feeItems.feeCategoryId) && parseInt(this.fees[row].feeItems.typeId, 10) !== 1 && parseInt(this.fees[row].feeItems.typeId, 10) !== 2 && userConfiguration.autoCalculateCoefficientByDate === true) {
          calculateQuantityByDay = true;
        }
      }

      if (calculateQuantityByDay) {
        // this.$emit('calculate-quantity');
        useJwt.calculateQuantity({ startDate: parseDateToString(this.fees[row].startDate), endDate: parseDateToString(this.fees[row].endDate) }).then(response => {
          const { quantity } = response.data;
          if (this.fees[row].feeItems.feeCategoryId === 'lease') {
            this.fees[row].quantity = quantity;
          } else {
            this.fees[row].coefficient = quantity;
          }

          const newInvoiceItem = this.calculateTotalForInvoiceItem(this.fees[row]);
          if (column === 'total') {
            this.fees[row].total = parseInt(changedData, 10);
            this.fees[row].subTotal = parseInt(changedData, 10);
          } else {
            this.fees[row].total = newInvoiceItem.total;
            this.fees[row].subTotal = newInvoiceItem.total;
          }

          this.fees[row].price = newInvoiceItem.price;
          this.fees[row].meterLogItems = newInvoiceItem.meterLogItems;
          this.fees[row].quantity = newInvoiceItem.quantity;
        }).catch(() => {

        });
      } else {
        const newInvoiceItem = this.calculateTotalForInvoiceItem(this.fees[row]);
        if (column === 'total') {
          this.fees[row].total = parseInt(changedData, 10);
          this.fees[row].subTotal = parseInt(changedData, 10);
        } else {
          this.fees[row].total = newInvoiceItem.total;
          this.fees[row].subTotal = newInvoiceItem.total;
        }

        this.fees[row].price = newInvoiceItem.price;
        this.fees[row].meterLogItems = newInvoiceItem.meterLogItems;
        this.fees[row].quantity = newInvoiceItem.quantity;
        this.onChange();
      }
    },
    reCalculateQuantityAndTotal(startDate, endDate, row) {
      const self = this;
      useJwt.calculateQuantity({ startDate, endDate }).then(response => {
        self.fees[row].quantity = response.data;
        self.onChange();
      });
    },
    onDeleteItem(item) {
      if (item.id && item.id > 0) {
        const remainData = this.fees.filter(fee => fee.id !== item.id);
        this.fees = remainData;
      } else {
        const remainData = this.fees.filter(fee => fee.feeItems.id !== item.feeItems.id);
        this.fees = remainData;
      }

      this.onChange();
    },
    onChange() {
      this.$emit('input', this.fees);
    },

  },
  setup() {
    const userConfiguration = JSON.parse(localStorage.getItem('userConfiguration'));
    const serviceFeeCalculationType = getServiceFeeCalculationType();
    const isAutoCalculateCoefficientByDate = userConfiguration.autoCalculateCoefficientByDate;

    const {
      getValidationState,
    } = formValidation();

    // Table Handlers
    const columns = [
      {
        label: 'Dịch vụ',
        field: 'name',
        sortable: false,
      },
      {
        label: 'Đơn giá',
        field: 'price',
        sortable: false,
      },
      {
        label: 'Chỉ số',
        field: 'meterLogItems',
        sortable: false,
        width: "220px",
      },
      {
        label: 'Số lượng/Số ngày',
        field: 'quantity',
        sortable: false,
        width: "120px",
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Hệ số',
        field: 'coefficient',
        sortable: false,
        width: "120px",
        hidden: userConfiguration ? !userConfiguration.enableCoefficient : false,
      },
      {
        label: 'Từ ngày',
        field: 'startDate',
        sortable: false,
        width: "140px",
      },
      {
        label: 'Đến ngày',
        field: 'endDate',
        sortable: false,
        width: "140px",
      },
      {
        label: 'Thành tiền',
        field: 'total',
        sortable: false,
        width: "110px",
        type: 'number',

      },
      {
        label: '',
        field: 'action',
        tdClass: 'text-center',
        sortable: false,
        width: "40px",
      },
    ];

    return {
      columns,
      serviceFeeCalculationType,
      getValidationState,
      isAutoCalculateCoefficientByDate,
    };
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
// .vgt-wrap {
//   .vgt-table {
//     thead {
//       th {
//         font-size: 0.75rem !important;
//         font-weight: 500;
//       }
//     }
//     td {
//       font-size: 0.75rem !important;
//       font-weight: 500;
//     }
//   }
// }
.invoice-items-table-container {
  .vgt-table {
    thead {
      th {
        font-size: 0.75rem !important;
        font-weight: 700;
      }
    }
    td {
      font-size: 0.75rem !important;
      font-weight: 500;
    }
  }
}
</style>
