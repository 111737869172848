/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserConfiguration } from '@/auth/utils';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoicePreviewModal(props) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const toastification = toast();

  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
  };

  const previewUrl = ref(null);

  //
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const fetchInvoiceHtml = () => {
    isLoading.value = true;
    previewUrl.value = `${process.env.VUE_APP_URL}/preview-invoice/${itemLocal.value.id}/${itemLocal.value.uuid}`;
    store
      .dispatch('invoice/previewInvoice', itemLocal.value.id)
      .then(response => {
        pdfUrl.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const showModal = () => {
    resetModal();
    fetchInvoiceHtml();
  };

  const downloadInvoicePDF = () => {
    store
      .dispatch('invoice/getInvoicePdf', itemLocal.value.id)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadInvoiceImage = () => {
    store
      .dispatch('invoice/getInvoiceImage', itemLocal.value.id)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaZaloZNS = () => {
    store
      .dispatch('invoice/sendInvoiceViaZaloZNS', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Vui lòng kiểm tra kết quả gửi Zalo thông báo tại mục \"Gửi thông báo\"");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaZaloNormal = () => {
    store
      .dispatch('invoice/sendInvoiceViaZaloNormal', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Hệ thống sẽ tiến hành gửi thông báo theo số điện thoại. LƯU Ý: hóa đơn được duyệt mới có thể gửi");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaApp = () => {
    store
      .dispatch('invoice/sendInvoiceViaApp', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Đã gửi thông báo phí qua App");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaEmail = () => {
    store
      .dispatch('invoice/sendInvoiceViaEmail', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Đã gửi thông báo phí qua Email");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchInvoiceHtml,
    showModal,
    downloadInvoicePDF,
    downloadInvoiceImage,
    sendInvoiceViaZaloZNS,
    sendInvoiceViaZaloNormal,
    sendInvoiceViaEmail,
    sendInvoiceViaApp,
    pdfUrl,
    isLoading,
    previewUrl,
  };
}
