<template>
  <b-modal
    :id="`modal-meter-log-${id}`"
    ref="refModal"
    title="Ghi chỉ số"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row v-if="!itemLocal.disableFilter">
          <b-col

            md="4"
          >
            <select-apartment
              v-model="itemLocal.apartment"
              :filter-active="true"
              :default-value="itemLocal.apartment"
              label="Tòa nhà"
              :enable-default-select="true"
            />
          </b-col>
          <b-col
            md="4"
          >
            <select-room
              v-model="itemLocal.room"
              :filter-active="true"
              :default-value="itemLocal.room"
              label="Phòng"
              :apartment="itemLocal.apartment"
            />
          </b-col>
          <b-col
            md="4"
          >
            <select-meter-type
              v-model="itemLocal.meterType"
              :default-value="itemLocal.meterType"
              label="Loại công tơ"
            />
          </b-col>

        </b-row>
        <b-row v-if="!itemLocal.disableFilter">

          <b-col :md="(itemLocal.id && itemLocal.id > 0) ? 12 : 6">
            <select-month
              v-model="itemLocal.month"
              required
              :default-value="itemLocal.month"
              label="Tháng chốt"
            />
          </b-col>
          <b-col cols="6">
            <select-date
              v-model="itemLocal.issueDate"
              required
              label="Ngày chốt"
              :default-value="itemLocal.issueDate"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <meter-table
              v-model="itemLocal.meters"
              :data="itemLocal.meters"
              :issue-date="itemLocal.issueDate"
              :apartment="itemLocal.apartment"
              :room="itemLocal.room"
              :month="itemLocal.month"
              :type="itemLocal.meterType"
              :no-record-only="itemLocal.noRecordOnly"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectMeterType from '@/views/components/SelectMeterType.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
import useMeterLogModal from './useMeterLogModal';
import MeterTable from './MeterTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,

    SelectApartment,
    SelectRoom,
    SelectMeterType,
    SelectDate,
    MeterTable,
    SelectMonth,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    approve: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  created() {
    if (this.item) {
      this.fetchMeters();
    }
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      fetchMeters,

      onSubmit,
    } = useMeterLogModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      fetchMeters,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
