<template>
  <vue-good-table
    class="mt-2"
    :columns="columns"
    :rows="meters"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: isRepresent -->
      <span v-if="props.column.field === 'firstIndex' || props.column.field === 'lastIndex' ">
        <!-- <b-form-group v-if="props.row.meter.smartDevice && props.column.field === 'lastIndex'">
          <b-form-input
            v-model="props.row.lastIndex"
            disabled
          />
        </b-form-group> -->
        <validation-provider
          #default="validationContext"
          :name="`${props.column.field} ${props.row.originalIndex}`"
          rules="required"
        >
          <number-input
            v-model="props.row[props.column.field]"
            :default-value="props.row[props.column.field]"
            :error="validationContext.errors[0]"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          />

        </validation-provider>
      </span>

      <!-- Column: Action -->
      <span v-else-if="props.column.field === 'issueDate'">
        <validation-provider
          #default="validationContext"
          :name="`Ngày chốt ${props.row.originalIndex}`"
          rules="required"
        >
          <select-date
            v-model="props.row.issueDate"
            :default-value="props.row.issueDate"
            :error="validationContext.errors[0]"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          />

        </validation-provider>
      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'imageFile'">
        <image-view
          :id="`image-file-${props.row.originalIndex}`"
          v-model="props.row.imageFile"
          :src="props.row.image"
          :instruction="null"
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
        />

      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BSpinner, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import SelectDate from '@/views/components/SelectDate.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import useJwt from '@/auth/jwt/useJwt';
import { ValidationProvider } from "vee-validate";
import ImageView from '@/views/components/ImageView.vue';
import moment from 'moment';

export default {
  components: {
    BSpinner,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    SelectDate,
    NumberInput,

    ValidationProvider,
    ImageView,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    type: {
      type: Object,
      default: null,
    },
    month: {
      type: String,
      default: moment(new Date()).format("MM-YYYY"),
    },
    noRecordOnly: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    issueDate: {
      type: [Date, String],
      default: null,
    },
  },
  data() {
    return {
      meters: this.data,
    };
  },
  watch: {
    apartment(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    room(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    type(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    month(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    noRecordOnly(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    issueDate() {
      this.meters = this.meters.map(meter => ({ ...meter, issueDate: this.issueDate }));
    },
  },
  created() {
    if (this.data.length === 0) {
      this.fetchData();
    }
  },
  methods: {
    changeCell(changedData, column, row) {
      this.meters[row][column] = changedData;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.meters);
    },
    fetchData() {
      if (!this.apartment) {
        return;
      }
      const filter = { };
      if (this.apartment && this.apartment.id > 0) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room && this.room.id > 0) {
        filter.roomId = this.room.id;
      }
      if (this.type) {
        filter.type = this.type.value;
      }
      if (this.month) {
        filter.month = this.month;
        filter.month = moment(this.month, "MM-YYYY").isValid() ? this.month : null;
      }
      filter.noRecordOnly = this.noRecordOnly;
      filter.active = true;
      useJwt.getMeters({ filter }).then(response => {
        this.meters = response.data.items.map(item => ({
          meter: item, issueDate: this.issueDate, firstIndex: item.lastIndex, lastIndex: item.smartDevice && item.smartDevice.currentValue > item.lastIndex ? parseInt(item.smartDevice.currentValue, 10) : item.lastIndex,
        }));
      });
    },
  },
  setup() {
    // Table Handlers
    const rows = [];
    const columns = [
      {
        label: 'Tên công tơ',
        field: 'meter.name',
        sortable: false,
      },
      {
        label: 'Chỉ số cũ',
        field: 'firstIndex',
        sortable: false,
        width: '120px',
      },
      {
        label: 'Chỉ số mới',
        field: 'lastIndex',
        sortable: false,
        width: '120px',
      },
      {
        label: 'Ngày chốt',
        field: 'issueDate',
        sortable: false,
        width: '150px',
      },
      {
        label: 'Hình ảnh',
        field: 'imageFile',
        sortable: false,
        width: '150px',
      },
    ];

    return {
      rows,
      columns,
    };
  },
};
</script>
