var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label,"state":_vm.state},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('v-select',{attrs:{"id":"room","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.rooms,"disabled":_vm.apartment == null || !_vm.enable,"placeholder":"Phòng"},on:{"input":_vm.roomChanged,"open":_vm.onOpen},scopedSlots:_vm._u([(_vm.showStatus)?{key:"option",fn:function(ref){
var name = ref.name;
var status = ref.status;
return [_c('span',{class:("text-" + (status.variant))},[_vm._v(_vm._s(name))]),_c('br'),_c('cite',{class:("text-" + (status.variant))},[_vm._v(_vm._s(status ? status.title : ''))])]}}:null,(_vm.enableAdd === true && _vm.$can('create', 'room'))?{key:"list-header",fn:function(){return [_c('li',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-room",modifiers:{"modal-room":true}}],staticClass:"list-header d-flex align-items-center my-50"},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"align-middle ml-25"},[_vm._v("Thêm phòng")])],1)]},proxy:true}:null,{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Không có dữ liệu ")]}}],null,true),model:{value:(_vm.room),callback:function ($$v) {_vm.room=$$v},expression:"room"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])],1),(_vm.enableAdd === true)?_c('room-modal',{attrs:{"item":{active: true, apartment: _vm.apartment, allowSelectApartment: false}},on:{"refetch-data":_vm.fetchRooms,"on-item-created":_vm.onItemCreated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }