<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="prepaid-detail-modal"
    ref="refModal"
    :title="`Lịch sử tiền thừa ${itemLocal.contract ? itemLocal.contract.name : ''}`"
    hide-footer
    size="lg"
    scrollable
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="showModal"
    @hidden="resetModal"
  >
    <!-- Body -->
    <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
      1. Thông tin chung
    </div>
    <b-list-group
      v-if="itemLocal.contract"
      class=""
    >
      <b-list-group-item>
        <b-row>
          <b-col class="font-weight-bolder">
            Khách hàng:
          </b-col>
          <b-col class=" ">
            {{ itemLocal.contract.name }}
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col class="font-weight-bolder">
            Tổng tiền thừa:
          </b-col>
          <b-col class="">
            {{ Number(totalAmount).toLocaleString() }}
          </b-col>
        </b-row>
      </b-list-group-item>

    </b-list-group>
    <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-1">
      2. Chi tiết lịch sử tiền thừa
    </div>
    <prepaid-table :data="data" />

  </b-modal>
</template>

<script>
import {
  BModal,
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
} from 'bootstrap-vue';
import { required } from '@validations';
import usePrepaidDetail from './usePrepaidDetail';
import PrepaidTable from './PrepaidTable.vue';

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
    PrepaidTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
      itemLocal,
      data,
      resetItemLocal,
      resetModal,
      fetchData,
      showModal,
      totalAmount,
    } = usePrepaidDetail(props);

    return {
      itemLocal,
      data,
      resetItemLocal,
      resetModal,
      fetchData,
      showModal,
      totalAmount,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
